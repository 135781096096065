import { Button } from 'baseui/button'
import { useContext, useEffect, useState } from 'react'
import {
  GDPRCheckbox,
  VendorCustomCheckbox
} from '../../components/CustomCheckbox'
import CustomCheckboxes from '../../components/CustomCheckboxes'
import { DocumentI, DocumentsList } from '../../components/DocumentsList'
import NonCompliantCheckbox from '../../components/NonCompliantCheckbox'
import { StepperContext, STEPS } from '../../contexts/StepperContext'
import { SignatureModal } from '../../components/SignatureModal'
import { UserCertificateSelect } from '../../components/UserCertifcateSelect'
import checkboxesService from '../../services/checkbox-service'
import {
  EVIDENCES,
  getEvidences,
  handleEvidence
} from '../../services/evidences'
import operationService, {
  Operation,
  Stepper,
  UserCertificate
} from '../../services/operations-service'
import signedOperationService, { Profile } from '../../services/sign-service'
import { getSystemInfo, initSystemInfo } from '../../services/system-info'
import styles from './Sign.module.scss'
import { theme } from '../../theming'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { STATES } from '../App/App'

const Sign = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams();
  const params: any = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });
  const { lang, oId, sId, documentId, token } = params as {
    lang: string
    oId: string
    sId: string
    documentId: string
    token: string
  }


  const stepperContext = useContext(StepperContext)
  const [componentLoaded, setComponentLoaded] = useState(false)
  const [isBusy, setIsBusy] = useState(true)
  const [operation, setOperation] = useState<Operation>()
  const [vendorCustomCheckboxes, setVendorCustomCheckboxes] = useState<Array<VendorCustomCheckbox>>([])
  const [GDPRCheckboxes, setGDPRCheckboxes] = useState<Array<GDPRCheckbox>>([])
  const [stepper, setStepper] = useState<Stepper>()
  const [selectedCertificate, setSelectedCertificate] = useState<UserCertificate | null>(null)
  const [nonCompliantSignChecked, setNonCompliantSignChecked] = useState<boolean>(false)
  const [nonCompliantText, setNonCompliantText] = useState('')
  const [checkboxes, setCheckboxes] = useState<Array<GDPRCheckbox | VendorCustomCheckbox> | null>([])
  const [documents, setDocuments] = useState<DocumentI[]>([])
  const [signModalOpen, setSignatureModalOpen] = useState<boolean>(false)
  const [signatureDraw, setSignatureDraw] = useState<any>({})
  const [signatureRequired, setSignatureRequired] = useState(false)
  //const [isLoading, setIsLoading] = useState(false)
  const [isFinishingOperation, setIsFinishingOperation] = useState(false)
  


  const allCustomCheckboxesChecked: boolean =
    vendorCustomCheckboxes.every((vcc) =>
      vcc.mandatory ? vcc.checked : true
    ) && GDPRCheckboxes.every((gdc) => gdc.checked)
  const isAllChecked: boolean =
    allCustomCheckboxesChecked &&
    (!nonCompliantSignChecked ||
      (nonCompliantSignChecked && nonCompliantText.length > 0)) &&
    (signatureRequired ? signatureDraw.content : true)

  useEffect(() => {
    handleEvidence(EVIDENCES.SHOW_FORM)
    initSystemInfo()
    stepperContext.setCurrentStep(STEPS.DOCUMENT_SIGN)
    setComponentLoaded(true)
  }, [])



  useEffect(() => {
    if (componentLoaded) {
      if (!oId || !sId) {
        //return setInaccessibleOperation(true)
      }
      Promise.all([
        getOperation(),
        getGDPRCheckboxes(),
        getOperationSteps()
        /* .then(() => {
          stepperContext.handleSteps(stepper)
        }) */
      ]).then(() => {
        setIsBusy(false)
      })
    }
  }, [componentLoaded, lang])

  useEffect(() => {
    if (operation) {
      getVendorCustomCheckboxes()
      setSignatureRequired(operation.type === 'SENDSIGN')
      const promises: any = []
      operation.files.forEach((file) => {
        promises.push(
          operationService
            .getFileContent(oId, sId, file._id, token)
            .then((content) => ({ ...file, content }))
        )
      })

      Promise.all(promises)
        .then((res) => {
          setDocuments(res)
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            return navigate(`/?${searchParams.toString()}&eStatus=${STATES.BAD_REQUEST}`)
          }
          throw err
        })
    }
  }, [operation, oId, sId, token])

  useEffect(() => {
    setCheckboxes([...GDPRCheckboxes, ...vendorCustomCheckboxes])
  }, [vendorCustomCheckboxes, GDPRCheckboxes])

  const profile: Profile = {
    dni: documentId
  }

  async function getOperation() {
    const operation = await operationService.getOperation(oId, sId, token)
    setOperation(operation);
  }

  async function getVendorCustomCheckboxes() {
    const vendorCustomCheckboxes: VendorCustomCheckbox[] =
      operation!.signer.options.customChecks.map(
        ({ checked, label, checkId, mandatory, tooltip }) => ({
          id: checkId,
          checked,
          label,
          mandatory,
          tooltip: tooltip || null
        })
      )
    setVendorCustomCheckboxes(vendorCustomCheckboxes)
  }

  async function getGDPRCheckboxes() {
    const GDPRCheckboxes = await checkboxesService.getGDPRCheckboxes(oId, i18n.language, token)
    setGDPRCheckboxes(GDPRCheckboxes);
  }

  async function getOperationSteps() {
    const stepper: Stepper = await operationService.getOperationSteps(oId, sId, token)
    setStepper(stepper);
    stepperContext.handleSteps(stepper)
  }

  useEffect(() => {
    if (isFinishingOperation === true) {
      handleEvidence(EVIDENCES.CLICK_FINISH)
      const evidences = getEvidences()
      const system = getSystemInfo()
      const signature = {
        certificate: selectedCertificate?.alias || 'rubricae',
        certificatePassword: selectedCertificate?.password || undefined,
        content: signatureDraw.content ? signatureDraw.content.split(';base64,')[1] : undefined,
        draws: (signatureDraw.content)
          ? operation!.signer.signature.draws
          : undefined,
        eventType: signatureDraw.content ? 'Pointer Event' : undefined,
        imagetype: signatureDraw.content ? 'image/png' : undefined,
        points: signatureDraw.points,
        electronicStamp: operation!.signer.signature.electronicStamp
      }

      signedOperationService
        .signedOperation({
          oId,
          sId,
          token,
          profile,
          evidences,
          signature,
          system,
          nonCompliantSignChecked,
          nonCompliantText
        })
        .then(() => {
          if (stepper!.signer.requireSignatureOtp) {
            navigate(`/otp?${searchParams.toString()}`)
          } else if (stepper!.signer.requireEmailValidation) {
            navigate(`/finishedsign?requireEmailValidation=${stepper!.signer.requireEmailValidation}&oId=${oId}&sId=${sId}`)
          } else {
            navigate(`/finishedsign?oId=${oId}&sId=${sId}`)
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            stepperContext.clearSteps()
            return navigate(`/?${searchParams.toString()}&eStatus=${STATES.BAD_REQUEST}`)
          }
          throw err
        })
        .finally(() => setIsFinishingOperation(false))
    }
  }, [isFinishingOperation])

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setIsFinishingOperation(true);
  }

  const handleOpenSignatureModal = () => {
    handleEvidence(EVIDENCES.SHOW_BOX_SIGNATURE)
    setSignatureModalOpen(true)
  }
  const handleSetSignature = (sig: any) => {
    handleEvidence(EVIDENCES.ACCEPT_SIGNATURE)
    setSignatureDraw(sig)
  }

  return isBusy ? (
    <>
      <div className={styles.container}>
        <p>Loading...</p>
      </div>
    </>
  ) : (
    <div className={styles.container}>
      {/* <DisplayXSmall>{t('SIGN_DOCUMENT')}</DisplayXSmall> */}
      <div className={styles.documentsContainer}>
        {documents.length > 0 && (
          <DocumentsList
            signature={signatureDraw}
            draws={operation!.signer.signature.draws}
            documents={documents}
          />
        )}
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <CustomCheckboxes
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
        />

        {operation!.signer.certificates.length > 0 && (
          <UserCertificateSelect
            setSelected={setSelectedCertificate}
            certificates={operation!.signer.certificates}
          />
        )}

        <div className={styles.buttonsContainer}>
          {signatureRequired && (
            <Button type="button" onClick={handleOpenSignatureModal}>
              {t('ADD_SIGN')}
            </Button>
          )}

          <Button
            type="submit"
            disabled={!isAllChecked || isFinishingOperation}
            overrides={{
              BaseButton: {
                style: {
                  display: 'flex',
                  flexDirection: 'row'
                }
              }
            }}>
            {t('FINISH')}
            {/* {isLoading ? (
              <>
                <LoadingSpinner /> {t('LOADING')}
              </>
            ) : (
              t('FINISH')
            )} */}
          </Button>
        </div>

        {theme.elements.nonCompliant && (
          <NonCompliantCheckbox
            checked={nonCompliantSignChecked}
            setChecked={setNonCompliantSignChecked}
            nonCompliantText={nonCompliantText}
            setNonCompliantText={setNonCompliantText}
          />
        )}
      </form>

      <SignatureModal
        signModalOpen={signModalOpen}
        setSignatureModalOpen={setSignatureModalOpen}
        setSignature={handleSetSignature}
      />
    </div>
  )
}

export default Sign
