import { Checkbox } from 'baseui/checkbox'
import { FormControl } from 'baseui/form-control'
import { Textarea } from 'baseui/textarea'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './NonCompliantCheckbox.module.scss'

function NonCompliantCheckbox({
  checked,
  setChecked,
  nonCompliantText,
  setNonCompliantText
}: {
  checked: boolean
  setChecked: React.Dispatch<React.SetStateAction<boolean>>
  nonCompliantText: string
  setNonCompliantText: React.Dispatch<React.SetStateAction<string>>
}) {
  const { t } = useTranslation()

  const [isVisited, setIsVisited] = useState<Boolean>(false)
  const shouldShowError: any = isVisited && nonCompliantText === ''

  const checkboxHandleChange = () => {
    setChecked(!checked)
  }
  const textareaHandleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNonCompliantText(event.currentTarget.value)
  }
  const handleBlur = () => {
    setIsVisited(true)
  }

  return (
    <div className={styles.container}>
      <Checkbox
        name="non-compliant-checkbox"
        checked={checked}
        onChange={checkboxHandleChange}>
        {t('NON_COMPLIANT_SIGN')}
      </Checkbox>

      {checked && (
        <FormControl
          error={shouldShowError ? t('MANDATORY_NON_COMPLIANT_REASON') : null}
          overrides={{
            ControlContainer: {
              style: {
                marginTop: '1rem',
                width: '30rem',
                maxWidth: '80vw'
              }
            }
          }}>
          <Textarea
            error={shouldShowError}
            value={nonCompliantText}
            onChange={textareaHandleChange}
            placeholder={t('NON_COMPLIANT_REASON')}
            onBlur={handleBlur}
          />
        </FormControl>
      )}
    </div>
  )
}

export default NonCompliantCheckbox
