import { ALIGN, Radio, RadioGroup } from 'baseui/radio'
import React, { useState } from 'react'
import { Draw } from '../services/operations-service'
import styles from './DocumentsList.module.scss'
import PdfViewer from './PdfViewer'

export interface DocumentI {
  content?: string
  contentModified?: string
  hash: string
  name: string
  size: number
  allowToEdit: boolean
  _id: string
}

export const DocumentsList = ({
  documents,
  signature,
  draws
}: {
  documents: DocumentI[]
  signature: any
  draws: Draw[]
}) => {
  const [expandedId, setExpandedId] = useState<string>(documents[0]._id)

  const getExpandedDocument = () =>
    documents.find((document) => document._id === expandedId)
  const handleChange = (id: string) => {
    setExpandedId(id)
  }

  return (
    <>
      <div className={styles.radioGroupContainer} style={{ marginBottom: '20px' }}>
        <RadioGroup
          onChange={(event) => handleChange(event.target.value)}
          value={expandedId}
          align={ALIGN.horizontal}
        >
          {documents.map((document) => (
            <Radio
              key={document._id}
              value={document._id}
              overrides={{
                Label: {
                  style: {
                    'max-width': '900px',
                    'width': window.outerWidth * 0.5 + 'px',
                    'white-space': 'nowrap',
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden'
                  }
                }
              }}
            >
              {document.name}
            </Radio>
          ))}
        </RadioGroup>
      </div>

      {expandedId && (
        <PdfViewer
          signature={signature}
          draws={draws}
          file={getExpandedDocument()}
          openConfig={{
            readOnlyMode: true
          }}
        />
      )}
    </>
  )
}
