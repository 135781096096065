import { api } from './api'

const sendAccessOtp = (oId: string, sId: string, documentId: string) => {
  return api
    .post(`/api/v2/operation/${oId}/signer/${sId}/send-sms-access-otp`, {
      dni: documentId
    })
    .then((response) => response.data)
}

const checkAccessOtp = (
  otp: string,
  oId: string,
  sId: string,
  token: string
) => {
  return api
    .post(
      `/api/v2/operation/${oId}/signer/${sId}/check-sms-access-otp`,
      { otp },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then((response) => response.data)
}

const sendSignatureOtp = (oId: string, sId: string, token: string) => {
  return api
    .post(
      `/api/v2/operation/${oId}/signer/${sId}/send-sms-signature-otp`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then((response) => response.data)
}

const checkSignatureOtp = (
  otp: string,
  oId: string,
  sId: string,
  token: string
) => {
  return api
    .post(
      `/api/v2/operation/${oId}/signer/${sId}/check-sms-signature-otp`,
      { otp },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then((response) => response.data)
}

const otpService = {
  sendAccessOtp,
  checkAccessOtp,
  sendSignatureOtp,
  checkSignatureOtp
}

export default otpService
