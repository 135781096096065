import React from 'react'
import ReactDOM from 'react-dom'
import { datadogRum } from '@datadog/browser-rum';

import reportWebVitals from './reportWebVitals'
import './styles/index.scss'
import './i18n'
import { BrowserRouter } from 'react-router-dom'
import App from './pages/App/App'

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from './config';

Sentry.init({
  dsn: "https://8407f0fc95bf4bea94ad3233a3a76452@o1152437.ingest.sentry.io/6734024",
  integrations: [new BrowserTracing()],
  environment: config.env,
  release: 'rubricae-onboarding@' + process.env.REACT_APP_VERSION,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.7,
});

// Initialize Datadog RUM
if (config.env === 'production') {
  datadogRum.init({
      applicationId: `${config.datadog.applicationId}`,
      clientToken: `${config.datadog.clientToken}`,
      site: 'datadoghq.eu',
      service: 'rubricae-onboarding',
      allowedTracingUrls: [
          `${config.api.url}`,
          (url) => url.startsWith(`${config.api.url}`)
      ],
      env: config.env as string,
      version: process.env.REACT_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
  });
}

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
