import { STATES } from "./pages/App/App"

/* export const validDocument = (value: String) => {
  if (!value) return false
  const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
  const nifRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
  const nieRegex = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
  const str = value.toString()
  if (!nifRegex.test(str) && !nieRegex.test(str)) return false
  const nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2')
  const letter = str.substr(-1)
  const charIndex = parseInt(nie.substr(0, 8)) % 23
  if (validChars.charAt(charIndex) === letter) return true
  return false
} */

export const expiredTokenError = (err: any, oId: string, sId: string) => {
  if (err.response?.status === 403) {
    return {
      redirect: {
        destination: `/?oId=${oId}&sId=${sId}&eStatus=${STATES.ERROR403}`
      }
    }
  } else {
    return {
      redirect: { destination: '/404' }
    }
  }
}

export const badRequestError = (err: any, oId: string, sId: string) => {
  if (err.response?.status === 400) {
    return {
      redirect: {
        destination: `/?oId=${oId}&sId=${sId}&eStatus=${STATES.NON_PENDANT_OPERATION}`
      }
    }
  }
}

export function arrayBufferToBase64(buffer: Buffer) {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
