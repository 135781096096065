import { GDPRCheckbox } from '../components/CustomCheckbox'
import { api } from './api'

export interface CheckboxResponse {
  templateId: string
  label: string
  name: string
  content: string
  labelPlainText: string
}

const getCheckboxes = (oId: string, sId: string, lang: string) => {
  return api
    .get(`/client/gdpr-templates?oId=${oId}&sId=${sId}`, {
      headers: {
        'Content-Language': lang
      }
    })
    .then((response) => {
      const data = response.data as CheckboxResponse[]
      const parsedData: GDPRCheckbox[] = data.map((element) => ({
        checked: false,
        label: element.label,
        id: element.templateId,
        name: element.name,
        content: element.content
      }))
      return parsedData
    })
}

const getGDPRCheckboxes = (oId: string, lang: string, token: string) => {
  return api
    .get(`/api/v2/operation/${oId}/gdpr-template`, {
      headers: {
        'Content-Language': lang,
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      const data = response.data as CheckboxResponse[]

      const parsedData: GDPRCheckbox[] = data.map((element) => ({
        checked: false,
        label: element.label,
        id: element.templateId,
        name: element.name,
        content: element.content
      }))
      return parsedData
    })
}

const checkboxesService = {
  getCheckboxes,
  getGDPRCheckboxes
}

export default checkboxesService
