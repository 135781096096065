import * as React from 'react'
import CustomCheckbox, {
  GDPRCheckbox,
  VendorCustomCheckbox
} from './CustomCheckbox'

function CustomCheckboxes({
  setCheckboxes,
  checkboxes
}: {
  setCheckboxes: React.Dispatch<
    React.SetStateAction<Array<GDPRCheckbox | VendorCustomCheckbox> | null>
  >
  checkboxes: Array<GDPRCheckbox | VendorCustomCheckbox> | null
}) {
  return (
    <div>
      {checkboxes && (checkboxes.length > 0) && checkboxes.map((checkbox) => {
        return (
          <CustomCheckbox
            checkbox={checkbox}
            key={checkbox.id}
            setCheckboxes={setCheckboxes}
          />
        )
      })}
    </div>
  )
}
export default CustomCheckboxes
