import { ParagraphMedium } from 'baseui/typography'
import { useTranslation } from 'react-i18next'

function ValidationEmailSent() {
  const { t } = useTranslation()
  return (
    <div>
      <ParagraphMedium>{t('SIGN_OK')}</ParagraphMedium>
      <ParagraphMedium>{t('SEND_EMAIL_VALIDATE')}</ParagraphMedium>
    </div>
  )
}

export default ValidationEmailSent
