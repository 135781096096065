import axios from "axios";
//const WhichBrowser = require("which-browser");

const systemInfo: SystemInfo = {
  useragent: '',
  ip: '',
  browser: '',
  os: '',
  device: '',
  camouflage: false,
  latitude: 0,
  longitude: 0,
  address: ''
}

export type SystemInfo = {
  useragent: string
  ip: string
  browser: string
  os: string
  camouflage: boolean
  device: string
  latitude: number
  longitude: number
  address: string
}

export function getSystemInfo(): SystemInfo {
  return systemInfo
}

export function initSystemInfo() {
  getIP();
  getGeolocation();
  getBrowser();
}

function updateSystemInfo(systemInfoPartial: Partial<SystemInfo>) {
  Object.assign(systemInfo, systemInfoPartial);
}

function getIP() {
  axios.get('https://wtfismyip.com/json')
    .then(res => {
      updateSystemInfo({
        ip: res.data.YourFuckingIPAddress,
        address: res.data.YourFuckingLocation
      });
    })
    .catch(err => {
      console.error(err);
    });
}

function getGeolocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {
      updateSystemInfo({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });
    });
  }
}

/**
 * TODO -> Añadir WichBrowser a React que da error por incompatibilidad con Webpack y compilación de Node
 */
function getBrowser() {
  /*const res = new WhichBrowser(navigator.userAgent);
  updateSystemInfo({
    useragent: navigator.userAgent,
    browser: `${res.browser.name ?? 'Unknown browser'} ${res.browser.version?.toString() ?? 'Unknown version'}`,
    os: res.os.toString(),
    device: res.device.type
  });*/
  updateSystemInfo({
    useragent: navigator.userAgent,
    browser: 'Unknown browser',
    os: 'Unknown OS',
    device: 'Unknown device'
  });
}