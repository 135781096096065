import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Checkbox } from 'baseui/checkbox'
import { Alert } from 'baseui/icon'
import {
  FocusOnce,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader
} from 'baseui/modal'
import { StatefulTooltip } from 'baseui/tooltip'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  handleCustomCheckEvidence,
  handleEvidence
} from '../services/evidences'

export interface GDPRCheckbox {
  checked: boolean
  label: string
  id: string
  name: string
  content: string
}
export interface VendorCustomCheckbox {
  checked: boolean
  label: string
  id: string
  mandatory: boolean
  tooltip: string | null
}

function CustomCheckbox({
  setCheckboxes,
  checkbox
}: {
  checkbox: GDPRCheckbox | VendorCustomCheckbox
  setCheckboxes: React.Dispatch<
    React.SetStateAction<Array<GDPRCheckbox | VendorCustomCheckbox> | null>
  >
}) {
  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const styles = {
    checkboxButton: {
      ...theme.typography.ParagraphMedium,
      fontWeight: 'bolder',
      cursor: 'pointer',
      "text-align":'left',
      border: 'none',
      background: 'transparent',
      padding: '0px',
      fontSize: 'inherit',
    },
    label: {
      '@media screen and (max-width: 880px)': {
        fontSize: '.9em'
      }
    }
  }
  const [isOpen, setOpen] = useState<boolean>(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    let { name, checked } = event.target
    setCheckboxes((prev) => {
      const checkboxes: GDPRCheckbox | VendorCustomCheckbox[] = Object.assign(
        [],
        prev
      )
      const checkedCheckbox = checkboxes.find((element) => element.id === name)
      if (checkedCheckbox) {
        checkedCheckbox.checked = checked
        if ('mandatory' in checkbox) {
          handleCustomCheckEvidence(checkedCheckbox)
        } else if (checkedCheckbox.checked) {
          handleEvidence(checkedCheckbox.id)
        }
      }
      return checkboxes
    })
  }
  const getCheckboxLabel = () => {
    const init = checkbox.label.indexOf('l1[')
    const end = checkbox.label.indexOf(']') + 1

    if ('mandatory' in checkbox) {
      if (!checkbox.tooltip) return checkbox.label

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {checkbox.label}
          <StatefulTooltip
            content={() => <Block padding={'.5rem'}>{checkbox.tooltip}</Block>}
            returnFocus
            autoFocus
          >
            <Alert
              size={'1.25rem'}
              overrides={{
                Svg: {
                  style: {
                    marginLeft: '.25rem'
                  }
                }
              }}
            />
          </StatefulTooltip>
        </div>
      )
    }

    const buttonElement = (
      <button
        className={css(styles.checkboxButton)}
        onClick={() => setOpen(true)}
      >
        {checkbox.name}
      </button>
    )

    const initElement = <span>{checkbox.label.slice(0, init)}</span>

    const endElement = <span>{checkbox.label.slice(end)}</span>
    return (
      <span className={css(styles.label)}>
        {initElement}
        {buttonElement}
        {end && endElement}
      </span>
    )
  }

  return (
    <div>
      <Checkbox
        name={checkbox.id}
        checked={checkbox.checked}
        onChange={handleChange}
        required={'mandatory' in checkbox ? checkbox.mandatory : true}
        overrides={{
          Root: {
            style: {
              marginBottom: '1rem',
              marginTop: '1rem',
              alignItems: 'center'
            }
          }
        }}
      >
        {getCheckboxLabel()}
      </Checkbox>

      {'content' in checkbox && (
        <Modal
          isOpen={isOpen}
          closeable
          onClose={() => setOpen(false)}
          size={'auto'}
          overrides={{
            Root: {
              style: {
                height: '100vh'
              }
            },
            Dialog: {
              style: {
                maxWidth: '70vw'
              }
            }
          }}
        >
          <FocusOnce>
            <ModalHeader>{checkbox.name}</ModalHeader>
          </FocusOnce>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: checkbox.content }} />
          </ModalBody>
          <ModalFooter>
            <ModalButton onClick={() => setOpen(false)}>
              {t('ACCEPT')}
            </ModalButton>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}

export default CustomCheckbox
