import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { ParagraphMedium } from 'baseui/typography'
import * as React from 'react'
import { FormEventHandler, useState } from 'react'
import { theme } from '../theming'
import { useTranslation } from 'react-i18next'
import { personalizationTranslate } from '../i18n'

function OtpInput({
  value,
  onChange,
  isValid,
  t
}: {
  value: string
  onChange: any
  isValid: Boolean
  t: any
}) {
  const { i18n } = useTranslation()
  const [isVisited, setIsVisited] = useState<Boolean>(false)
  const shouldShowError: false = !isValid && isVisited

  return (
    <div>
      <ParagraphMedium>{personalizationTranslate('elements.otp.sendedText', i18n.language)}</ParagraphMedium>
      <FormControl
        error={shouldShowError ? t('INCORRECT_OTP') : null}
        label={t('OTP')}>
        <Input
          required
          id="input-otp"
          name={t('OTP')}
          value={value}
          onChange={onChange}
          error={shouldShowError}
          placeholder={personalizationTranslate('elements.otp.inputOtpPlaceholder', i18n.language)}
          onBlur={() => setIsVisited(true)}
          maxLength={6}
        />
      </FormControl>
    </div>
  )
}
export default OtpInput
