import { Button } from 'baseui/button'
import { DisplayXSmall } from 'baseui/typography'
import { useContext, useEffect, useState } from 'react'
import {
  GDPRCheckbox,
  VendorCustomCheckbox
} from '../../components/CustomCheckbox'
import CustomCheckboxes from '../../components/CustomCheckboxes'
import { DocumentI, DocumentsList } from '../../components/DocumentsList'
import NonCompliantCheckbox from '../../components/NonCompliantCheckbox'
import { StepperContext, STEPS } from '../../contexts/StepperContext'
import { SignatureModal } from '../../components/SignatureModal'
import { UserCertificateSelect } from '../../components/UserCertifcateSelect'
import checkboxesService from '../../services/checkbox-service'
import {
  EVIDENCES,
  getEvidences,
  handleEvidence
} from '../../services/evidences'
import operationService, {
  Operation,
  OperationOnboardingSteps,
  Stepper,
  UserCertificate
} from '../../services/operations-service'
import signedOperationService, { Profile } from '../../services/sign-service'
import { getSystemInfo, initSystemInfo } from '../../services/system-info'
import { expiredTokenError } from '../../utils'
import styles from './EditDocument.module.scss'
import { theme } from '../../theming'
import LoadingSpinner from '../../components/LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { STATES } from '../App/App'
import receivedFileService from '../../services/received-files.service'
import PdfViewer from '../../components/PdfViewer'
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE } from 'baseui/modal'

const EditDocument = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams();
  const params: any = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });
  const { lang, oId, sId, documentId, token } = params as {
    lang: string
    oId: string
    sId: string
    documentId: string
    token: string
  }

  const stepperContext = useContext(StepperContext)
  const [componentLoaded, setComponentLoaded] = useState(false)
  const [isBusy, setIsBusy] = useState(true)
  const [operation, setOperation] = useState<Operation>()
  const [filesToEdit, setFilesToEdit] = useState<Array<DocumentI>>([])
  const [fileEditingIndex, setFileEditingIndex] = useState<number>(0)
  const [editingStepButtonText, setEditingStepButtonText] = useState<string>('')
  const [fileEditing, setFileEditing] = useState<DocumentI>()
  const [stepper, setStepper] = useState<Stepper>()
  const [operationOnboardingSteps, setOperationOnboardingSteps] = useState<OperationOnboardingSteps>()
  const [modalFinishEditingIsOpen, setModalFinishEditingIsOpen] = useState<boolean>(false)

  useEffect(() => {
    // Controlar evidencia de edición de documentos
    // handleEvidence(EVIDENCES.SHOW_FORM)
    (async () => {
      stepperContext.setCurrentStep(STEPS.DOCUMENT_MODIFICATION)
      const operationResume = await operationService.getOnboardingSteps(oId, sId);
      setOperationOnboardingSteps(operationResume);
      setComponentLoaded(true)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      if (componentLoaded) {
        await getOperationSteps()
      }
    })()
  }, [componentLoaded, lang])


  useEffect(() => {
    (async () => {
      if (operationOnboardingSteps) {
        await getOperation();
      }
    })()
  }, [operationOnboardingSteps])

  useEffect(() => {
    (async () => {
      if (filesToEdit.length > 0) {
        await showEditingFile()
        setIsBusy(false)
      }
    })()
  }, [filesToEdit])

  useEffect(() => {
    (async () => {
      if (operation) {
        const filesToEdit: Array<DocumentI> = [];
        for (const file of operation.files) {
          if (file.allowToEdit === true) {
            const fileContent = await operationService.getFileContent(oId, sId, file._id, token);
            filesToEdit.push({ ...file, content: fileContent });
          }
        }
        setFilesToEdit(filesToEdit);
      }
    })()
  }, [operation, oId, sId, token])

  useEffect(() => {
    (async () => {
      if (isBusy === false) {
        if ((fileEditingIndex === filesToEdit.length)) {
          //setModalFinishEditingIsOpen(true);
          setIsBusy(true);
          finishEditDocuments();
        } else {
          showEditingFile();
        }
      }
    })()

  }, [isBusy, fileEditingIndex])

  const profile: Profile = {
    dni: documentId
  }

  async function getOperation() {
    const operation = await operationService.getOperation(oId, sId, token);
    setOperation(operation);
  }

  async function showEditingFile() {
    const fileId = filesToEdit[fileEditingIndex]._id;
    const fileEditing: DocumentI = await receivedFileService.get(fileId, token);

    setFileEditing(fileEditing);
    setNextButtonValues();
  }

  function setNextButtonValues() {
    if (fileEditingIndex === filesToEdit.length - 1) {
      setEditingStepButtonText(t('GO_TO_SIGN'));
    } else {
      setEditingStepButtonText(t('EDIT_NEXT_DOCUMENT'));
    }
  }
  async function showNextEditingFile() {
    const fileModified = await (window as any).PDFViewerApplication.getDocumentInBase64();
    filesToEdit[fileEditingIndex].contentModified = fileModified;

    setFileEditingIndex(fileEditingIndex + 1);
  }


  async function getOperationSteps() {
    const stepper: Stepper = await operationService.getOperationSteps(oId, sId, token);
    setStepper(stepper);
    stepperContext.handleSteps(stepper);
  }

  async function finishEditDocuments() {
    for (let file of filesToEdit) {
      if (file.contentModified) {
        await receivedFileService.update(file._id, file.contentModified, token);
      }
    }
    navigate(`/sign?${searchParams.toString()}`);
  }

  return isBusy ? (
    <>
      <div className={styles.container}>
        <p>Loading...</p>
      </div>
    </>
  ) : (
    <div className={styles.container}>

      {/* <Modal
        onClose={() => setModalFinishEditingIsOpen(false)}
        isOpen={modalFinishEditingIsOpen}
        closeable={false}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
        overrides={{
          Root: {
            style: () => ({
              zIndex: '10'
            })
          }
        }}
      >
        <ModalHeader>{t('FINALIZAR_EDICION')}</ModalHeader>
        <ModalBody>
          {t('VA_A_FINALIZAR_LA_EDICION_DE_LOS_DOCUMENTOS')}
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={(e: Event) => {
              setFileEditingIndex(fileEditingIndex - 1);
              setModalFinishEditingIsOpen(false)
            }}>
            {t('CANCEL')}
          </ModalButton>
          <ModalButton onClick={finishEditDocuments}>
            {t('ACCEPT')}
          </ModalButton>
        </ModalFooter>
      </Modal> */}

      {fileEditing ? (
        <>
          <h3 style={{ alignSelf: 'self-start' }}>{t('DOCUMENT')}: {fileEditing.name}</h3>
        </>
      ) : null}

      <PdfViewer
        file={fileEditing}
      />

      <div className={styles.buttonsContainer}>

        <Button
          type="button"
          onClick={showNextEditingFile}
          disabled={isBusy}
          overrides={{
            BaseButton: {
              style: {
                display: 'flex',
                flexDirection: 'row'
              }
            }
          }}>
          {editingStepButtonText}
        </Button>
      </div>
    </div>
  )
}

export default EditDocument
