import { api } from './api'

export interface Token {
  accessToken: string
  operation: {
    signer: {
      state: number
    }
    state: number
  }
}

const getAccessToken = (
  oId: string,
  sId: string,
  documentId: string,
  otp?: string
) => {
  return api
    .post('/oauth2/signer/token', { oId, sId, documentId, otp })
    .then((response) => response.data as Token)
}

const tokenService = {
  getAccessToken
}

export default tokenService
