import { ParagraphMedium } from 'baseui/typography'
import { useTranslation } from 'react-i18next'

function ValidationEmailCheckOperation() {
  const { t } = useTranslation()
  return (
    <div>
      <ParagraphMedium>{t('CHECK_OPERATION')}</ParagraphMedium>
    </div>
  )
}

export default ValidationEmailCheckOperation
