import React, { useContext, useEffect, useState } from 'react'
import ValidationSuccess from '../../components/ValidationSuccess'
import validateEmailService from '../../services/validate-service'
import { StepperContext, STEPS } from '../../contexts/StepperContext'
import { useSearchParams } from 'react-router-dom'
import { FocusOnce, Modal, ModalBody, ModalHeader } from 'baseui/modal'
import { useTranslation } from 'react-i18next'
import ValidationEmailCheckOperation from '../../components/ValidationEmailCheckOperation'

function Validate() {
  const { t, i18n } = useTranslation()
  const stepperContext = useContext(StepperContext)
  const [searchParams] = useSearchParams();
  const params: any = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });
  const { lang, oId, sId, token } = params as {
    lang: string
    oId: string
    sId: string
    token: string
  }

  const [componentLoaded, setComponentLoaded] = useState(false)
  const [isBusy, setIsBusy] = useState(true)
  const [isOpen, setOpen] = useState<boolean>(false)

  useEffect(() => {
    stepperContext.setCurrentStep(STEPS.EMAIL_VALIDATED_SIGN)
    setComponentLoaded(true)
  }, [])

  useEffect(() => {
    if (componentLoaded) {
      if (!oId || !sId || !token) {
        //return setInaccessibleOperation(true)
      }

      validateEmailOperation()
    }
  }, [componentLoaded, lang])

  async function validateEmailOperation() {
    try {
      await validateEmailService.validateEmailOperation(oId, sId, token)
      setIsBusy(false)
    } catch (err: any) {
      console.error('err', err);
      setIsBusy(true);
      if (err.response?.status === 400) {
        setOpen(true)
        setIsBusy(false)
      } else {
        throw err
      }
    }
  }

  return isBusy ? (
    <>
      <ValidationEmailCheckOperation />
    </>
  ) : (
    <>
      {
        <Modal isOpen={isOpen} closeable={false}>
          <ModalHeader>{t('NON_PENDANT_EMAIL_VALIDATION_MODAL_TITLE')}</ModalHeader>
          <FocusOnce>
            <ModalBody>{t('NON_PENDANT_EMAIL_VALIDATION_MODAL_TEXT')}</ModalBody>
          </FocusOnce>
        </Modal>
      }
      <ValidationSuccess />
    </>
  )
}

export const getServerSideProps = async ({
  locale,
  query
}: {
  locale: string
  query: { oId: string; sId: string; token: string }
}) => {
  const { messages, errors } = await import(`../../../public/locales/${locale}.json`)

  await validateEmailService.validateEmailOperation(
    query.oId,
    query.sId,
    query.token
  )

  return {
    props: {
      messages,
      errors
    }
  }
}

export default Validate
