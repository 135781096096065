import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import SignOk from '../../components/SignOk'
import ValidationEmailSent from '../../components/ValidationEmailSent'
import { StepperContext, STEPS } from '../../contexts/StepperContext'

const FinishedSign = () => {
  const [searchParams] = useSearchParams()
  const params: any = {}
  searchParams.forEach((value: string, key: string) => {
    params[key] = value
  })
  const { requireEmailValidation} = params as {
    requireEmailValidation: string
  }
  const stepperContext = useContext(StepperContext)
  useEffect(() => {
    requireEmailValidation
      ? stepperContext.setCurrentStep(STEPS.SEND_EMAIL)
      : stepperContext.setCurrentStep(STEPS.SIGN_FINISHED)
  }, [requireEmailValidation])
  return requireEmailValidation ? <ValidationEmailSent /> : <SignOk />
}

export default FinishedSign
