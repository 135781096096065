import { Button } from 'baseui/button'
import { ButtonGroup, SIZE } from 'baseui/button-group';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import languagesService from '../services/languages-service'

export type allowedLanguages = {
  'es-ES': string,
  'en-GB': string,
  'fr-FR': string,
  'pt-PT': string,
  'pt-BR': string,
  'de': string,
  'it': string
};
export const LANGUAGES = [
  { code: 'es', region: 'es-ES', name: 'Español (España)' },
  { code: 'en', region: 'en-GB', name: 'English (Great Britain)' },
  { code: 'fr', region: 'fr-FR', name: 'Français (France)' },
  { code: 'pt', region: 'pt-PT', name: 'Português (Portugal)' },
  { code: 'pt', region: 'pt-BR', name: 'Português (Brasil)' },
  { code: 'de', region: 'de', name: 'Deutsch' },
  { code: 'it', region: 'it', name: 'Italiano' }
]

function LanguageSelector() {
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params: any = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });

  const [apiLanguages, setApiLanguages] = useState<string[]>([]);

  useEffect(() => {
    getAPILanguages()
  }, [])

  async function getAPILanguages() {
    const apiLangs = await languagesService.getLanguagesFromAPI()
    setApiLanguages(apiLangs)
  }

  const allowLangs = LANGUAGES.filter((lang) => {
    const langFinded = apiLanguages.find((apiLanguages) => apiLanguages === lang.region);
    if (langFinded) {
      return lang;
    }
  })

  return (
    <ButtonGroup selected={(allowLangs.findIndex((lang) => (lang.region === i18n.language))) || 0} size={SIZE.mini}>
      {allowLangs && allowLangs.map((language, index) => (
        <Button
          onClick={() =>
            i18n.changeLanguage(language.region).then(() =>
              setSearchParams({ ...params, lang: language.region })
            )
          }
          key={`LANGUAGE_${index}`}
          overrides={{
            BaseButton: {
              style: () => ({
                borderTopLeftRadius: index === 0 ? '.5rem' : 0,
                borderBottomLeftRadius: index === 0 ? '.5rem' : 0,
                borderTopRightRadius:
                  index === apiLanguages.length - 1 ? '.5rem' : 0,
                borderBottomRightRadius:
                  index === apiLanguages.length - 1 ? '.5rem' : 0
              })
            }
          }}>
          {language.code.toUpperCase()}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default LanguageSelector
