import { ParagraphMedium } from 'baseui/typography'
import { useTranslation } from 'react-i18next'
import { theme } from '../theming'
import { personalizationTranslate } from '../i18n'

function ValidationSuccess() {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <ParagraphMedium>{t('VALIDATION_SUCCESS')}</ParagraphMedium>{' '}
      <ParagraphMedium>{personalizationTranslate('elements.finished.signOkText1', i18n.language)}</ParagraphMedium>
      <ParagraphMedium>{t('THANKS')}</ParagraphMedium>
    </div>
  )
}

export default ValidationSuccess
