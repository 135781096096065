import { Input } from 'baseui/input'
import { Select } from 'baseui/select'
import { LabelMedium } from 'baseui/typography'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserCertificate } from '../services/operations-service'

export const UserCertificateSelect = ({
  setSelected,
  certificates
}: {
  setSelected: Dispatch<SetStateAction<UserCertificate | null>>
  certificates: UserCertificate[]
}) => {
  const rubricaeCertificate = {
    alias: 'Rubricae',
    blocked: false,
    default: false,
    name: 'Rubricae',
    passwordProtection: false
  }
  const { t } = useTranslation()
  const [selectValue, setSelectValue] = useState<UserCertificate[]>([rubricaeCertificate])
  const [passwordValue, setPasswordValue] = useState<string>('')

  const handleSelectChange = (params: any) => {
    setSelectValue(params.value)
    setSelected(params.value[0] || null)
  }
  const handlePasswordChange = (ev: any) => {
    const password = ev.target.value
    setPasswordValue(password)
    if (password)
      setSelected((prev: any) => {
        if (prev) return { ...prev, password: ev.target.value }
      })
  }

  useEffect(() => {
    certificates.unshift(rubricaeCertificate)
  }, [certificates])

  return (
    <div
      style={{
        padding: '1rem 0',
        maxWidth: '80%',
        width: '20rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
      }}>
        <LabelMedium>{t('SELECT_YOUR_CERTIFICATE')}</LabelMedium>
      <Select
        options={certificates}
        labelKey="name"
        valueKey="alias"
        searchable={false}
        onChange={handleSelectChange}
        value={selectValue}
        clearable={false}
      />

      {selectValue.length > 0 && selectValue[0].passwordProtection && (
        <Input
          type="password"
          value={passwordValue}
          onChange={handlePasswordChange}
          placeholder={t('CERTIFICATE_PASSWORD')}
        />
      )}
    </div>
  )
}
