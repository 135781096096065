import { Button } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '../theming'
import { personalizationTranslate } from '../i18n'

function Dni({
  value,
  change,
  isLoading
}: {
  value: string
  change: any
  isLoading: boolean
}) {
  const [isVisited, setIsVisited] = useState<Boolean>(false)
  const shouldShowError: Boolean = (value === '') && isVisited
  const { i18n, t } = useTranslation()

  return (
    <div>
      <FormControl
        label={t('ID_DOCUMENT')}
        error={shouldShowError ? t('INCORRECT_ID') : null}>
        <Input
          id="input-id"
          name="dni"
          value={value}
          onChange={change}
          onBlur={() => setIsVisited(true)}
          placeholder= {personalizationTranslate('elements.identification.identificationPlaceholder', i18n.language)}
          autoComplete="off"
        />
      </FormControl>

      <Button
          type="submit"
          disabled={(value === '') || isLoading}
          overrides={{
            BaseButton: {
              style: {
                display: 'flex',
                flexDirection: 'row'
              }
            }
          }}>
          {t('START')}
        </Button>
    </div>
  )
}
export default Dni
