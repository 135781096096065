import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { FocusOnce, Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import { DisplayXSmall, ParagraphSmall } from 'baseui/typography'
import { useCallback, useContext, useEffect, useState } from 'react'
import OtpInput from '../../components/OtpInput'
import { StepperContext, STEPS } from '../../contexts/StepperContext'
import otpService from '../../services/otp-service'
import tokenService from '../../services/token-service'
import styles from './Otp.module.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import operationService, { OperationOnboardingSteps, Stepper } from '../../services/operations-service'
import { STATES } from '../App/App'
import { theme as theming } from '../../theming';
import { personalizationTranslate } from '../../i18n'

const Otp = () => {
  const navigate = useNavigate();
  const [css, theme] = useStyletron()
  const { t, i18n } = useTranslation()

  const [searchParams] = useSearchParams();
  const params: any = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });
  const { oId, sId, documentId, token } = params as {
    oId: string
    sId: string
    documentId: string
    token?: string
  }

  const stepperContext = useContext(StepperContext)
  useEffect(() => {
    stepperContext.setCurrentStep(STEPS.OTP_VERIFICATION)
    if (!token) stepperContext.handleSteps(null)
  }, [])

  const [isValid, setIsValid] = useState<Boolean>(false)
  const [otp, setOtp] = useState<string>('')
  const [visible, setVisible] = useState<boolean>(false)
  const [otpCounter, setOtpCounter] = useState<number>(0)
  const [isOpen, setOpen] = useState<boolean>(false)
  const [clickCounter, setClickCounter] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [stepper, setStepper] = useState<Stepper>()
  const [operationOnboardingSteps, setOperationOnboardingSteps] = useState<OperationOnboardingSteps | null>(null)

  const LIMIT_SMS_SENDED = 1
  const LIMIT_CLICKED = 1

  const requireOtp = useCallback(() => {
    if (token !== undefined) {
      return otpService.sendSignatureOtp(oId, sId, token).catch((err) => {
        if (err.response?.status === 400) {
          return setOpen(true)
        }
        throw err
      })
    } else {
      return otpService.sendAccessOtp(oId, sId, documentId).catch((err) => {
        if (err.response?.status === 400) {
          return setOpen(true)
        }
        throw err
      })
    }
  }, [oId, sId, token, documentId])

  async function getOperationSteps() {
    if (token) {
      const stepper: Stepper = await operationService.getOperationSteps(oId, sId, token)
      setStepper(stepper);
      stepperContext.handleSteps(stepper)
    }
  }

  async function getOperationOnboardingSteps() {
    const operationResume = await operationService.getOnboardingSteps(oId, sId);
    setOperationOnboardingSteps(operationResume);
  }

  const getText = (text: string) => {
    const init = text.indexOf('<1>')
    const end = text.indexOf('</1>')

    return (
      <ParagraphSmall>
        {text.slice(0, init)}
        <button
          type="button"
          className={css({
            ...theme.typography.ParagraphSmall,
            fontWeight: 'bolder',
            cursor: 'pointer',
            border: 'none',
            background: 'transparent',
            padding: 0
          })}
          onClick={handleClick}
          disabled={otpCounter >= LIMIT_SMS_SENDED}>
          {text.slice(init + 3, end)}
        </button>
        {text.slice(end + 4)}
      </ParagraphSmall>
    )
  }

  useEffect(() => {
    getOperationSteps()
    getOperationOnboardingSteps()
    /* en acceso de otp avisa de que ya se ha excedido de sms */
    if (localStorage.getItem('resend') === 'false') {
      setOtpCounter(otpCounter + 1)
      setOpen(true)
    }
  }, [])

  useEffect(() => {
    const show = () => {
      if (localStorage.getItem('resend') === 'false') {
        setVisible(false)
      } else {
        setVisible(true)
      }
    }
    const timer = setTimeout(show, 12000)
    return () => clearTimeout(timer)
  }, [requireOtp, token])

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    setIsValid(value.length === 6)
    setOtp(value)
  }

  const handleClick = () => {
    requireOtp()
    setOtpCounter(otpCounter + 1)
    setOpen(true)
  }
  const handleSubmit = (event: { preventDefault: () => void }) => {
    setClickCounter(clickCounter + 1)
    event.preventDefault()
    if (isValid) {
      if (token === undefined) {
        setIsLoading(true)
        tokenService
          .getAccessToken(oId, sId, documentId, otp)
          .then((token) => {
            if (token.operation.signer.state === 2) {
              stepperContext.clearSteps()
              navigate(`/finishedsign?requireEmailValidation=true&oId=${oId}&sId=${sId}`)
            } else if (token.operation.state !== 1) {
              stepperContext.clearSteps()
              navigate(`/?${searchParams.toString()}&eStatus=${STATES.NON_PENDANT_OPERATION}`)
            } else if (operationOnboardingSteps && operationOnboardingSteps.filesToEdit.length > 0) {
              navigate(`/edit-documents?${searchParams.toString()}&token=${token.accessToken}`)
            } else {
              navigate(`/sign?${searchParams.toString()}&token=${token.accessToken}`)
            }
          })
          .catch(() => {
            setIsValid(false)
            setIsLoading(false)
          })
      } else {
        setIsLoading(true)
        otpService
          .checkSignatureOtp(otp, oId, sId, token)
          .then(() => {

            if (stepper!.signer.requireEmailValidation) {
              navigate(`/finishedsign?requireEmailValidation=${stepper!.signer.requireEmailValidation}&oId=${oId}&sId=${sId}`)
            } else {
              navigate(`/finishedsign?oId=${oId}&sId=${sId}`)
            }
          })
          .catch(() => {
            setIsValid(false)
            setIsLoading(false)
          })
      }
    }
  }

  return (
    <>
      {/*
        * TODO -> Cambiar el texto de la modal para indicar que no se pueden enviar mas SMSs
        */}
      {
        <Modal isOpen={isOpen} closeable={false}>
          {otpCounter === LIMIT_CLICKED
            ?
            <>
              <ModalHeader>{t('OTP_MODAL_TITLE')}</ModalHeader>
              <FocusOnce>
                <ModalBody>{t('OTP_MODAL_TEXT')}</ModalBody>
              </FocusOnce>
            </>
            :
            <>
              <ModalHeader>{personalizationTranslate('elements.errors.notPendantOperationTitle', i18n.language)}</ModalHeader>
              <FocusOnce>
                <ModalBody>{personalizationTranslate('elements.errors.notPendantOperationText', i18n.language)}</ModalBody>
              </FocusOnce>
            </>
          }
          <ModalFooter>
            <ModalButton
              autoFocus
              onClick={() => {
                setOpen(false)
              }}>
              {t('ACCEPT')}
            </ModalButton>
          </ModalFooter>
        </Modal>
      }

      <div className={styles.container}>
        <DisplayXSmall>{t('OTP_VERIFICATION')}</DisplayXSmall>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OtpInput
            value={otp}
            isValid={isValid}
            onChange={handleInputChange}
            t={t}
          />
          <Button
            type="submit"
            disabled={!isValid || otpCounter >= LIMIT_CLICKED + 1 || isLoading}
            overrides={{
              BaseButton: {
                style: {
                  display: 'flex',
                  flexDirection: 'row'
                }
              }
            }}>
            {/* Siguiente */}
            {/* {isLoading ? (
              <>
                <LoadingSpinner />
                {t('LOADING')}
              </>
            ) : */}
            {token === undefined ? (
              t('SEE_DOCUMENT')
            ) : (
              t('VERIFY_OTP')
            )}
          </Button>
        </form>
        {visible && otpCounter < LIMIT_SMS_SENDED && (
          <div>
            {getText(theming.elements.otp.resendSmsText[i18n.language as keyof typeof theming.elements.otp.resendSmsText])}
          </div>
        )}
      </div>
    </>
  )
}
export default Otp
