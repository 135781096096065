import { Button } from 'baseui/button'
import { FocusOnce, Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from 'baseui/modal'
import React, { useRef } from 'react'
import { useContext, useEffect, useState } from 'react'

//@ts-ignore : declarar los tipos
import ReactSignatureCanvas from '@rubricae/react-signature-canvas'
import { useTranslation } from 'react-i18next'

export const SignatureModal = ({
  signModalOpen,
  setSignatureModalOpen,
  setSignature
}: {
  signModalOpen: boolean
  setSignatureModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSignature: React.Dispatch<React.SetStateAction<any>>
}) => {
  function converMultiArray(final: any) {
    return final.reduce((acc: any, val: any) => Array.isArray(val) ? acc.concat(converMultiArray(val)) : acc.concat(val), []);
  }
  const { t } = useTranslation()
  const ref = useRef<ReactSignatureCanvas>(null)
  const [isOpen, setOpen] = useState<boolean>(false)
  const handleAddSign = () => {
    const puntos = converMultiArray(ref.current?.toData())
    if (puntos.length < 25) {
      setOpen(true)
    } else {
      setSignature({
        points: puntos,
        content: ref.current?.getCanvas().toDataURL()
      })
      setSignatureModalOpen(false)
    }
  }
  const reiniciarModal = () => {
    setOpen(false)
    setSignatureModalOpen(false)
  }

  return (
    <Modal
      isOpen={signModalOpen}
      onClose={() => setSignatureModalOpen(false)}
      overrides={{
        Root: {
          style: {
            zIndex: 8
          }
        },
        Dialog: {
          style: {
            width: 'fit-content',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }
        }
      }}>
      <div
        style={{
          width: 'fit-content',
          border: '1px solid lightgray',
          margin: '2rem 0',
          overflow: 'hidden',
          maxWidth: 300
        }}>
        <Modal isOpen={isOpen} closeable={false}
          overrides={{
            Root: {
              style: {
                zIndex: 10
              }
            }
          }}>
          <ModalHeader>{t('SIGNATURE_MODAL_TITLE')}</ModalHeader>
          <FocusOnce>
            <ModalBody>{t('SIGNATURE_MODAL_TEXT')}</ModalBody>
          </FocusOnce>
          <ModalFooter>
            <ModalButton
              autoFocus
              onClick={() => {
                reiniciarModal()
              }}>
              {t('ACCEPT')}
            </ModalButton>
          </ModalFooter>
        </Modal>
        <ReactSignatureCanvas penColor='rgba(0,0,139,0.80)' canvasProps={{ width: 300, height: 150 }} ref={ref} />
      </div>
      <Button onClick={handleAddSign}>{t('ADD')}</Button>
    </Modal>
  )
}
