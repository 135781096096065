import { api } from './api'

const validateEmailOperation = (oId: string, sId: string, token: string) => {
  return api
    .post(
      `/api/v2/operation/${oId}/signer/${sId}/email-validation`,
      { token },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then((response) => response.data)
}

const validateEmailService = {
  validateEmailOperation
}

export default validateEmailService
