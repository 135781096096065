import { api } from './api'

export interface Profile {
  dni: string
}

const signedOperation = ({
  oId,
  sId,
  token,
  profile,
  evidences,
  signature,
  system,
  nonCompliantSignChecked,
  nonCompliantText
}: {
  oId: string
  sId: string
  token: string
  profile: Profile
  evidences: any
  signature: any
  system: any
  nonCompliantSignChecked: boolean
  nonCompliantText?: string
}) => {
  return api
    .post(
      `/api/v2/operation/${oId}/signer/${sId}/sign`,
      {
        sId,
        oId,
        profile,
        evidences,
        signature,
        system,
        notCompliant: nonCompliantSignChecked,
        observations: nonCompliantText
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then((response) => response.data)
}

const signedOperationService = {
  signedOperation
}

export default signedOperationService
