import { DocumentI } from "../components/DocumentsList"
import { arrayBufferToBase64 } from "../utils";
import { api } from "./api"

async function get(id: string, token: string): Promise<DocumentI> {
  return api
    .get(`/received-files/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      const file = response.data;
      file.content = arrayBufferToBase64(file.content.data);
      return file;
    });
}

async function update(id: string, content: string, token: string) {
  return api
    .patch(`/received-files/${id}`, {
      content
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
}

const receivedFileService = {
  get,
  update
}

export default receivedFileService