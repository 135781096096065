import { createTheme, Theme } from 'baseui'
import logo from './assets/images/rubricae-logo.svg'
import customizationService from './services/customization-service'
import i18n from 'i18next'
import _ from 'lodash'
import { allowedLanguages } from './components/LanguageSelector'

export type customization = {
  subdomain: string
  logo: {
    src: string
    height: number
    width: number
  }
  initializeVariables: Function
  style: {
    primitives: {
      primary: string
      primaryDark: string
      primaryA: string // Text color
      cardBg: string
      primaryFontFamily: string
    }
    overrides: {
      tickFillSelectedHover: string
      inputBorder: string
      inputFill: string
      inputFillActive: string
    }
  }
  elements: {
    errors: {
      notPendantOperationTitle: allowedLanguages,
      notPendantOperationText: allowedLanguages
    },
    identification: {
      showIds: boolean,
      identificationPlaceholder: allowedLanguages
    },
    conditions: {
      startSignProcessText: allowedLanguages
    }
    otp: {
      sendedText: allowedLanguages
      inputOtpPlaceholder: allowedLanguages
      resendSmsText: allowedLanguages
    }
    finished: {
      signOkText1: allowedLanguages,
      signOkText2: allowedLanguages
    }
    languageSelector: boolean
    nonCompliant: boolean
    fontUrl: string
  }
}
const colors = {
  primary: '#263145',
  primaryDark: '#141a24',
  primaryA: '#263145', // Text color
  cardBg: 'rgba(0, 0, 0, 0.05)'
}

export let theme: customization;

export const initializeTheme = (): customization => {
  return theme = {
    subdomain: 'base',
    logo: { src: logo, width: 250, height: 300 },
    initializeVariables,
    style: {
      primitives: { ...colors, primaryFontFamily: 'Montserrat' },
      overrides: {
        tickFillSelectedHover: colors.primaryDark,
        inputBorder: 'transparent',
        inputFill: 'white',
        inputFillActive: 'white'
      }
    },
    elements: {
      errors: {
        notPendantOperationTitle: {
          'es-ES': i18n.t('NON_PENDANT_OPERATION_MODAL_TITLE', { lng: 'es-ES' }),
          'en-GB': i18n.t('NON_PENDANT_OPERATION_MODAL_TITLE', { lng: 'en-GB' }),
          'pt-PT': i18n.t('NON_PENDANT_OPERATION_MODAL_TITLE', { lng: 'pt-PT' }),
          'pt-BR': i18n.t('NON_PENDANT_OPERATION_MODAL_TITLE', { lng: 'pt-BR' }),
          'fr-FR': i18n.t('NON_PENDANT_OPERATION_MODAL_TITLE', { lng: 'fr-FR' }),
          'de': i18n.t('NON_PENDANT_OPERATION_MODAL_TITLE', { lng: 'de' }),
          'it': i18n.t('NON_PENDANT_OPERATION_MODAL_TITLE', { lng: 'it' })
        },
        notPendantOperationText: {
          'es-ES': i18n.t('NON_PENDANT_OPERATION_MODAL_TEXT', { lng: 'es-ES' }),
          'en-GB': i18n.t('NON_PENDANT_OPERATION_MODAL_TEXT', { lng: 'en-GB' }),
          'pt-PT': i18n.t('NON_PENDANT_OPERATION_MODAL_TEXT', { lng: 'pt-PT' }),
          'pt-BR': i18n.t('NON_PENDANT_OPERATION_MODAL_TEXT', { lng: 'pt-BR' }),
          'fr-FR': i18n.t('NON_PENDANT_OPERATION_MODAL_TEXT', { lng: 'fr-FR' }),
          'de': i18n.t('NON_PENDANT_OPERATION_MODAL_TEXT', { lng: 'de' }),
          'it': i18n.t('NON_PENDANT_OPERATION_MODAL_TEXT', { lng: 'it' })
        }
      },
      conditions: {
        startSignProcessText: {
          'es-ES': i18n.t('START_SIGN_PROCESS', { lng: 'es-ES' }),
          'en-GB': i18n.t('START_SIGN_PROCESS', { lng: 'en-GB' }),
          'pt-PT': i18n.t('START_SIGN_PROCESS', { lng: 'pt-PT' }),
          'pt-BR': i18n.t('START_SIGN_PROCESS', { lng: 'pt-BR' }),
          'fr-FR': i18n.t('START_SIGN_PROCESS', { lng: 'fr-FR' }),
          'de': i18n.t('START_SIGN_PROCESS', { lng: 'de' }),
          'it': i18n.t('START_SIGN_PROCESS', { lng: 'it' })
        }
      },
      identification: {
        showIds: true,
        identificationPlaceholder: {
          'es-ES': i18n.t('DNI/NIE/CIF', { lng: 'es-ES' }),
          'en-GB': i18n.t('DNI/NIE/CIF', { lng: 'en-GB' }),
          'pt-PT': i18n.t('DNI/NIE/CIF', { lng: 'pt-PT' }),
          'pt-BR': i18n.t('DNI/NIE/CIF', { lng: 'pt-BR' }),
          'fr-FR': i18n.t('DNI/NIE/CIF', { lng: 'fr-FR' }),
          'de': i18n.t('DNI/NIE/CIF', { lng: 'de' }),
          'it': i18n.t('DNI/NIE/CIF', { lng: 'it' })
        }
      },
      otp: {
        sendedText: {
          'es-ES': i18n.t('ENTER_OTP_CODE', { lng: 'es-ES' }),
          'en-GB': i18n.t('ENTER_OTP_CODE', { lng: 'en-GB' }),
          'pt-PT': i18n.t('ENTER_OTP_CODE', { lng: 'pt-PT' }),
          'pt-BR': i18n.t('ENTER_OTP_CODE', { lng: 'pt-BR' }),
          'fr-FR': i18n.t('ENTER_OTP_CODE', { lng: 'fr-FR' }),
          'de': i18n.t('ENTER_OTP_CODE', { lng: 'de' }),
          'it': i18n.t('ENTER_OTP_CODE', { lng: 'it' })
        },
        inputOtpPlaceholder: {
          'es-ES': i18n.t('VERIFICATION_CODE', { lng: 'es-ES' }),
          'en-GB': i18n.t('VERIFICATION_CODE', { lng: 'en-GB' }),
          'pt-PT': i18n.t('VERIFICATION_CODE', { lng: 'pt-PT' }),
          'pt-BR': i18n.t('VERIFICATION_CODE', { lng: 'pt-BR' }),
          'fr-FR': i18n.t('VERIFICATION_CODE', { lng: 'fr-FR' }),
          'de': i18n.t('VERIFICATION_CODE', { lng: 'de' }),
          'it': i18n.t('VERIFICATION_CODE', { lng: 'it' })
        },
        resendSmsText: {
          'es-ES': i18n.t('RESEND_OTP_SMS', { lng: 'es-ES' }),
          'en-GB': i18n.t('RESEND_OTP_SMS', { lng: 'en-GB' }),
          'pt-PT': i18n.t('RESEND_OTP_SMS', { lng: 'pt-PT' }),
          'pt-BR': i18n.t('RESEND_OTP_SMS', { lng: 'pt-BR' }),
          'fr-FR': i18n.t('RESEND_OTP_SMS', { lng: 'fr-FR' }),
          'de': i18n.t('RESEND_OTP_SMS', { lng: 'de' }),
          'it': i18n.t('RESEND_OTP_SMS', { lng: 'it' })
        }
      },
      finished: {
        signOkText1: {
          'es-ES': i18n.t('OTHER_SIGNERS', { lng: 'es-ES' }),
          'en-GB': i18n.t('OTHER_SIGNERS', { lng: 'en-GB' }),
          'pt-PT': i18n.t('OTHER_SIGNERS', { lng: 'pt-PT' }),
          'pt-BR': i18n.t('OTHER_SIGNERS', { lng: 'pt-BR' }),
          'fr-FR': i18n.t('OTHER_SIGNERS', { lng: 'fr-FR' }),
          'de': i18n.t('OTHER_SIGNERS', { lng: 'de' }),
          'it': i18n.t('OTHER_SIGNERS', { lng: 'it' })
        },
        signOkText2: {
          'es-ES': i18n.t('PERSONALIZED_TEXT1', { lng: 'es-ES' }),
          'en-GB': i18n.t('PERSONALIZED_TEXT1', { lng: 'en-GB' }),
          'pt-PT': i18n.t('PERSONALIZED_TEXT1', { lng: 'pt-PT' }),
          'pt-BR': i18n.t('PERSONALIZED_TEXT1', { lng: 'pt-BR' }),
          'fr-FR': i18n.t('PERSONALIZED_TEXT1', { lng: 'fr-FR' }),
          'de': i18n.t('PERSONALIZED_TEXT1', { lng: 'de' }),
          'it': i18n.t('PERSONALIZED_TEXT1', { lng: 'it' })
        }
      },
      languageSelector: true,
      nonCompliant: true,
      fontUrl:
        'https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'
    }
  }
}
const initializeVariables = () => {
  const root = document.documentElement
  root.style.setProperty('--card-bg', colors.cardBg)
}
let baseTheme: Theme
export function loadTheme(theme: customization) {
  baseTheme = createTheme(theme.style.primitives, theme.style.overrides)
}
loadTheme(initializeTheme())
export function getTheme(): Theme {
  return baseTheme
}
export async function initTheme(): Promise<void> {
  initializeVariables()
  const host = window?.location?.host
  await i18n.loadLanguages('es-ES')
  const providerTheme = await customizationService.getProviderCustomization(host)
  _.merge(initializeTheme(), providerTheme)
  loadTheme(theme)
}
