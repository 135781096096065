import { createContext, useState } from 'react'

export const StepperContext = createContext<any>(null)

export enum STEPS {
  IDENTIFICATION = 'IDENTIFICATION',
  DOCUMENT_MODIFICATION = 'DOCUMENT_MODIFICATION',
  DOCUMENT_SIGN = 'DOCUMENT_SIGN',
  OTP_VERIFICATION = 'OTP_CONFIRMATION',
  SEND_EMAIL = 'SEND_EMAIL',
  SIGN_FINISHED = 'SIGN_FINISHED',
  EMAIL_VALIDATED_SIGN = 'EMAIL_VALIDATED_SIGN'
}

type Stepper = {
  createdAt: string;
  filesToEdit: boolean;
  state: number;
  signer: {
    requireAccessOtp?: boolean;
    requireSignatureOtp?: boolean;
    requireEmailValidation?: boolean;
  };
}

export const StepperContextProvider = ({ children }: any) => {
  const [currentStep, setCurrentStep] = useState<string | null>(null)
  const [steps, setSteps] = useState<string[]>([])

  const handleSteps = (stepper: Stepper) => {
    let steps = [STEPS.IDENTIFICATION]
    if (stepper === null) {
      steps.push(STEPS.OTP_VERIFICATION, STEPS.DOCUMENT_SIGN, STEPS.SIGN_FINISHED)
    } else {
      addAccessOtpStep(steps, stepper);
      addFilesToEditStep(steps, stepper);
      addDocumentSignStep(steps, stepper);
      addSignatureOtpStep(steps, stepper);
      addEmailValidationStep(steps, stepper);
      addSignFinishedStep(steps, stepper);
      // if (stepper.signer.requireAccessOtp) {
      //   steps.push(STEPS.OTP_VERIFICATION, STEPS.DOCUMENT_SIGN)
      // }
      // if (stepper?.signer.requireSignatureOtp) {
      //   steps.push(STEPS.DOCUMENT_SIGN, STEPS.OTP_VERIFICATION)
      // }
      // if (
      //   !stepper?.signer.requireAccessOtp &&
      //   !stepper?.signer.requireSignatureOtp &&
      //   !stepper?.signer.requireEmailValidation
      // ) {
      //   steps.push(STEPS.DOCUMENT_SIGN, STEPS.SIGN_FINISHED)
      // }
      // if (
      //   !stepper?.signer.requireAccessOtp &&
      //   !stepper?.signer.requireSignatureOtp &&
      //   stepper?.signer.requireEmailValidation
      // ) {
      //   steps.push(STEPS.DOCUMENT_SIGN)
      // }
      // if (
      //   stepper?.signer.requireEmailValidation &&
      //   !stepper?.signer.requireSignatureOtp
      // ) {
      //   steps.push(STEPS.SEND_EMAIL)
      // } else if (
      //   stepper?.signer.requireEmailValidation &&
      //   !stepper?.signer.requireAccessOtp
      // ) {
      //   steps.push(STEPS.SEND_EMAIL)
      // } else if (
      //   stepper?.signer.requireEmailValidation &&
      //   stepper?.signer.requireAccessOtp
      // ) {
      //   steps.push(STEPS.SEND_EMAIL)
      // }
      // if (!steps.includes(STEPS.SIGN_FINISHED)) {
      //   steps.push(STEPS.SIGN_FINISHED)
      // }
    }
    setSteps(steps)
  }

  const addAccessOtpStep = (steps: STEPS[], stepper: Stepper) => {
    if (stepper.signer.requireAccessOtp) {
      steps.push(STEPS.OTP_VERIFICATION)
    }
  };
  const addFilesToEditStep = (steps: STEPS[], stepper: Stepper) => {
    if (stepper.filesToEdit) {
      steps.push(STEPS.DOCUMENT_MODIFICATION)
    }
  }
  const addDocumentSignStep = (steps: STEPS[], stepper: Stepper) => {
    steps.push(STEPS.DOCUMENT_SIGN);
  };
  const addSignatureOtpStep = (steps: STEPS[], stepper: Stepper) => {
    if (stepper.signer.requireSignatureOtp) {
      steps.push(STEPS.OTP_VERIFICATION)
    }
  };
  const addEmailValidationStep = (steps: STEPS[], stepper: Stepper) => {
    if (stepper.signer.requireEmailValidation) {
      steps.push(STEPS.SEND_EMAIL)
    }
  };
  const addSignFinishedStep = (steps: STEPS[], stepper: Stepper) => {
    steps.push(STEPS.SIGN_FINISHED);
  };

  const clearSteps = () => {
    setSteps([])
  }

  const value: any = {
    steps,
    currentStep,
    setCurrentStep,
    handleSteps,
    clearSteps
  }

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  )
}
