import { VendorCustomCheckbox } from './../components/CustomCheckbox'

interface CustomCheckboxEvidence {
  checkId: string
  checked: boolean
  label: string
  timestamp: string
  tooltip?: string | null
}

export enum EVIDENCES {
  CLICK_FINISH = 'clickFinish',
  SELECT_PDF = 'selectPDF',
  SHOW_FORM = 'showForm',
  ACCEPT_SIGNATURE = 'acceptSignature',
  SHOW_BOX_SIGNATURE = 'showBoxSignature',
  CUSTOM_CHECKS = 'customChecks'
}

let evidences: {
  [key: string]: string | CustomCheckboxEvidence[]
} = {}

export const handleEvidence = (key: EVIDENCES | string) => {
  if (Object.keys(evidences).length === 0) {
    initializeEvidences()
  }

  const timestamp = new Date().getTime()
  evidences[key] = timestamp.toString()

  localStorage.setItem('evidences', JSON.stringify(evidences))
}

export const handleCustomCheckEvidence = (checkbox: VendorCustomCheckbox) => {
  const timestamp = new Date().getTime()

  const customCheckbox: CustomCheckboxEvidence = {
    checkId: checkbox.id,
    checked: checkbox.checked,
    label: checkbox.label,
    timestamp: `${timestamp}`
  }
  if (checkbox.tooltip) customCheckbox.tooltip = checkbox.tooltip

  const customChecks = evidences[
    EVIDENCES.CUSTOM_CHECKS
  ] as CustomCheckboxEvidence[]
  const foundIndex = customChecks.findIndex(
    (check) => check.checkId === customCheckbox.checkId
  )
  if (foundIndex > -1) customChecks.splice(foundIndex, 1)

  customChecks.push(customCheckbox)

  localStorage.setItem('evidences', JSON.stringify(evidences))
}

export const clearEvidences = () => {
  localStorage.removeItem('evidences')
}

export const initializeEvidences = () => {
  const lsEvidences = localStorage.getItem('evidences')
  evidences = lsEvidences
    ? JSON.parse(lsEvidences)
    : { [EVIDENCES.CUSTOM_CHECKS]: [] }
}

export const getEvidences = () => evidences
