import { useContext } from 'react'
import { ParagraphSmall } from 'baseui/typography'
import { StepperContext } from '../contexts/StepperContext'
import { useTranslation } from 'react-i18next'

export const Stepper = () => {
  const stepperContext = useContext(StepperContext)
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '1rem'
      }}
    >
      {stepperContext?.steps.map((step: string, index: number) => (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem'
          }}
          key={step}
        >
          <ParagraphSmall
            overrides={{
              Block: {
                style: {
                  fontWeight:
                    stepperContext?.currentStep === step ? 'bold' : 'normal',
                  ...(window.outerWidth < 500 &&
                  stepperContext?.currentStep !== step
                    ? { display: 'none' }
                    : {})
                }
              }
            }}
          >
            {t(step)}
          </ParagraphSmall>
          {window.outerWidth>500 && index < stepperContext?.steps.length - 1 && (
            <ParagraphSmall>{'>'}</ParagraphSmall>
          )}
        </span>
      ))}
    </div>
  )
}
