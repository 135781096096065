import config from '../config'
import axios from 'axios'
import { toaster } from 'baseui/toast'
import { getErrors } from './errors'

export const api = axios.create({ baseURL: config.api.url })

api.interceptors.response.use(
  (response) => response,
  (err) => {
    console.error(`\nError on API call:`)
    console.error({
      method: err.config.method,
      url: err.config.url,
      data: err.config.data,
      headers: err.config.headers
    })

    if (err.response?.status === 409 || err.response?.status === 400) throw err // Skip if OTP handled error
    
    const errorCode = 'ERROR_' + err.response.data
    const errors = getErrors()
    if (errors && toaster) {
      const errorMsg = errors[errorCode]
      toaster.negative(errorMsg ? errorMsg : errors.ERROR_DEFAULT, {
        overrides: {
          Body: {
            style: {
              width: '100%',
              maxWidth: '40rem'
            }
          }
        }
      })
    }
    throw err
  }
)
