import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { theme } from './theming'

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: 'locales/{{lng}}.json'
    },
    debug: true,
    fallbackLng: ['es-ES', 'en-GB', 'fr-FR', 'pt-PT', 'pt-BR', 'de', 'it'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export function personalizationTranslate(
  path: string,
  lang: string,
  replaceObj?: Record<string, string>
): string {
  const pathArray = path.split('.')
  const res = pathArray.reduce((acc: any, curr: string) => {
    return acc[curr]
  }, theme)
  if (!replaceObj) {
    return res[lang] || res['es-ES'] || ''
  }
  return Object.keys(replaceObj).reduce((acc: string, curr: string): string => {
    return acc.replace(curr, replaceObj[curr])
  }, res[lang] || res['es-ES'] || '')

}


export default i18n
