import { api } from './api'

const getLanguagesFromAPI = () => {
    return api
    .get(`/h/la`, {})
      .then((response) => {
        const data = response.data as [string]
        return data
      })
}

const languagesService = {
    getLanguagesFromAPI
}

export default languagesService