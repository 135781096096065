import { DisplayXSmall, ParagraphMedium } from 'baseui/typography'
import { useTranslation } from 'react-i18next'
import { personalizationTranslate } from '../i18n'
import { useSearchParams } from 'react-router-dom'

function SignOk() {
  const { i18n, t } = useTranslation()
  const params: any = {}
  const [searchParams] = useSearchParams()

  searchParams.forEach((value: string, key: string) => {
    params[key] = value
  })
  const { oId, sId } = params as {
    oId: string
    sId: string
  }
  return (
    <div>
      <DisplayXSmall>{t('SIGN_OK')}</DisplayXSmall>
      <ParagraphMedium>
        {personalizationTranslate(
          'elements.finished.signOkText1',
          i18n.language
        )}
      </ParagraphMedium>
      <ParagraphMedium
        dangerouslySetInnerHTML={{
          __html: personalizationTranslate(
            'elements.finished.signOkText2',
            i18n.language,
            { '{{oId}}': oId, '{{sId}}': sId }
          )
        }}
      >
      </ParagraphMedium>
      <ParagraphMedium>{t('THANKS')}</ParagraphMedium>
    </div>
  )
}

export default SignOk
