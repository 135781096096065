import { customization } from '../theming';
import { api } from './api';

const getProviderCustomization = (host: string) => {
  return api.get(`/company/onboarding-theme/${host}`)
    .then((response) => {
      const providerTheme = response.data as customization
      return providerTheme
    })
}

const customizationService = {
  getProviderCustomization
}

export default customizationService
