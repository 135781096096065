import { Button } from 'baseui/button'
import { ListItem, ListItemLabel } from "baseui/list";
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import operationService from '../services/operations-service'
import socketIO from 'socket.io-client';
import { ParagraphMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { SIZE, Spinner } from 'baseui/spinner';
import config from '../config';

const socket = socketIO(config.api.socket);

function VideoIdentification({
  oId,
  sId,
  setDocumentId,
  onGetSignerIdentificationNumber
}: {
  oId: string
  sId: string
  setDocumentId: any
  onGetSignerIdentificationNumber: (id: string) => void;
}): any {
  const { t } = useTranslation();
  const [css] = useStyletron();

  const [isBusy, setIsBusy] = useState(false);
  const [newWindow, setNewWindow] = useState<any>();
  const [statusChange, setStatusChange] = useState<boolean>(false);

  const startVideoIdentification = function () {
    setIsBusy(true);
  }

  useEffect(() => {
    socket.on('videoIdChangeStatus', (message: { documentId: string }) => {
      setDocumentId(message.documentId);
      onGetSignerIdentificationNumber(message.documentId);
      setStatusChange(true);
    })

    return () => {
      socket.off('videoIdChangeStatus');
    }
    
  }, []);
  useEffect(() => {
    closeNewBrowserWindow();
  }, [statusChange]);

  useEffect(() => {
    if (isBusy === true) {
      createVideoId();
    }

    async function createVideoId() {
      const videoId = await operationService.createVideoId(oId, sId);
      openNewBrowserWindow(videoId);

      socket.emit('subscribeToRoom', `${oId}@${sId}`);
    }
  }, [isBusy]);

  function openNewBrowserWindow(url: string) {
    const win = window.open(url, '_blank');
    win?.focus();
    setNewWindow(win);
  }

  function closeNewBrowserWindow() {
    if (newWindow) {
      newWindow.close();
    }
    setIsBusy(false);
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <ParagraphMedium>{t('YOU_ARE_ABOUT_TO_START_A_VIDEO_IDENTIFICATION_IN_ORDER_TO_CONTINUE_WITH_THE_SIGNING_PROCESS')}</ParagraphMedium>
      <ParagraphMedium>{t('PLEASE_HAVE_THE_FOLLOWING_ITEMS_HANDY')}:</ParagraphMedium>
      <ul
        className={css({
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: '#000'
        })}
      >
        <ListItem>
          <ListItemLabel>{t('IDENTITY_DOCUMENT')}</ListItemLabel>
        </ListItem>
        <ListItem>
          <ListItemLabel>{t('WEBCAM_OR_MOBILE')}</ListItemLabel>
        </ListItem>
        <ListItem>
          <ListItemLabel>{t('AREA_WITH_GOOD_LIGHTING')}</ListItemLabel>
        </ListItem>
      </ul>
      <br/>
      {isBusy
        ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spinner $size={SIZE.large} $as="span" /></div>
        : <Button onClick={startVideoIdentification}>{t('START_VIDEO_IDENTIFICACION')}</Button>
      }
    </div>
  )
}
export default VideoIdentification
