import { Button } from 'baseui/button'
import { FocusOnce, Modal, ModalBody, ModalHeader } from 'baseui/modal'
import { DisplayMedium, ParagraphLarge } from 'baseui/typography'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import checkboxesService from '../services/checkbox-service'
import { clearEvidences } from '../services/evidences'
import tokenService from '../services/token-service'
import { GDPRCheckbox, VendorCustomCheckbox } from './CustomCheckbox'
import CustomCheckboxes from './CustomCheckboxes'
import styles from './Welcome.module.scss'
import { STATES } from '../pages/App/App'
import { TOKENERROR } from '../pages/Identification/Identification'
import { personalizationTranslate } from '../i18n'

function Welcome() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const params: any = {};
  searchParams.forEach((value: string, key: string) => {
    params[key] = value;
  });
  const { lang, oId, sId, documentId, eStatus } = params as {
    lang: string
    oId: string
    sId: string
    documentId?: string
    eStatus?: string
  }

  const [componentLoaded, setComponentLoaded] = useState(false)
  const [checkboxes, setCheckboxes] = useState<Array<GDPRCheckbox | VendorCustomCheckbox> | null>(null)
  const [inaccessibleOperation, setInaccessibleOperation] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    // Clear evidences on welcome page
    clearEvidences()
    localStorage.removeItem('resend')

    setComponentLoaded(true)
  }, [])

  useEffect(() => {
    if (componentLoaded) {
      if (!oId || !sId) {
        return setInaccessibleOperation(true)
      }
      eStatus && setInaccessibleOperation(true)
      searchCheckboxElementsInAPI()
    }
  }, [componentLoaded, lang])

  function isAllChecked(): boolean {
    if (checkboxes) {
      return checkboxes.every((checkbox) => checkbox.checked)
    }
    return false
  }

  async function searchCheckboxElementsInAPI() {
    const checkboxElements = await checkboxesService
      .getCheckboxes(oId, sId, i18n.language)
      .catch((err) => {
        if (err.response?.status === 400) {
          setInaccessibleOperation(true)
          return null
        }
        throw err
      })
    setCheckboxes(checkboxElements);
  }

  const handleNextButtonClick = async () => {
    if (!documentId) {
      navigate(`/identification?${searchParams.toString()}`)
    } else {
      setIsLoading(true)
    }
  }

  /**
   * Chequea que el componente está cargando para deshabilitar el botón siguiente y enviar la petición
   */
  useEffect(() => {
    if (componentLoaded) {
      getAccessToken();
    }

    async function getAccessToken() {
      try {
        if (documentId) {
          const token = await tokenService.getAccessToken(oId, sId, documentId)
          if (token) {
            {
              if (token.operation.state !== 1) {
                navigate(`/?${searchParams.toString()}&eStatus=${STATES.NON_PENDANT_OPERATION}`)
              } else if ((token.operation.state === 1) && (token.operation.signer.state === 5)) {
                navigate(`/otp?${searchParams.toString()}&token=${token.accessToken}`)
              } else {
                navigate(`/sign?${searchParams.toString()}&token=${token.accessToken}`)
              }
            }
          }
        }
      } catch (err: any) {
        if (err.response.data.code === TOKENERROR.RESEND_OTP_OK) {
          localStorage.setItem('resend', 'true')
          navigate(`/otp?${searchParams.toString()}`)
        } else if (err.response.data.code === TOKENERROR.MAX_OTP_SENT) {
          localStorage.setItem('resend', 'false')
          navigate(`/otp?${searchParams.toString()}`)
        } else {
          setInaccessibleOperation(true)
        }
      }
    }

  }, [isLoading])

  /* !componentLoaded ? (
    <LoadingSpinner />
  ) :  */

  return (
    <>
      {!inaccessibleOperation ? (
        <div className={styles.container}>
          <DisplayMedium
            overrides={{
              Block: {
                style: {
                  fontWeight: '600'
                }
              }
            }}>
            {t('WELCOME')}
          </DisplayMedium>
          <ParagraphLarge>{personalizationTranslate('elements.conditions.startSignProcessText', i18n.language)}</ParagraphLarge>
          <div className={styles['conditions-container']}>
            <CustomCheckboxes
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
            />
          </div>

          <Button
            type="submit"
            disabled={!isAllChecked() || isLoading}
            onClick={handleNextButtonClick}
            overrides={{
              BaseButton: {
                style: {
                  display: 'flex',
                  flexDirection: 'row'
                }
              }
            }}>
            {isLoading ? (
              <>
                {/* <LoadingSpinner /> */}
                {t('LOADING')}
              </>
            ) : (
              t('NEXT')
            )}
          </Button>
        </div>
      ) : (
        <>
          <Modal isOpen={true} closeable={false}>
            <ModalHeader>{personalizationTranslate('elements.errors.notPendantOperationTitle', i18n.language)}</ModalHeader>
            <FocusOnce>
              <ModalBody>{personalizationTranslate('elements.errors.notPendantOperationText', i18n.language)}</ModalBody>
            </FocusOnce>
          </Modal>
        </>
      )}
    </>
  )
}

export default Welcome
