import { DocumentI } from '../components/DocumentsList'
import { api } from './api'

export interface OperationOnboardingSteps {
  operationState: number,
  filesToEdit: Array<File>,
  identificationDni: {
    required: Boolean,
    validated: Boolean
  },
  identificationVideoId: {
    required: Boolean,
    validated: Boolean
  },
  accessOtp: {
    required: Boolean,
    validated: Boolean,
    count: number
  },
  signatureOtp: {
    required: Boolean,
    validated: Boolean,
    count: number
  },
  emailValidation: {
    required: Boolean,
    validated: Boolean
  },
  signerState: number
}

export type File = {
  id: string
}

export interface Stepper {
  state: number
  filesToEdit: boolean
  createdAt: Date
  signer: {
    requireAccessOtp: boolean
    requireSignatureOtp: boolean
    requireEmailValidation: boolean
    state: number
  }
}

export interface UserCertificate {
  alias: string
  blocked: boolean
  default: boolean
  name: string
  passwordProtection: boolean
  password?: string
}

export interface Draw {
  filename?: string
  page: number
  height: number
  width: number
  left: number
  top: number
  options?: Record<string, unknown>
}

export interface ElectronicStamp {
  position: {
    left: number
    page: number
    top: number
  }
}

export interface Operation {
  files: DocumentI[]
  state: number
  createdAt: Date
  signer: {
    profile: {
      dni: string
      name: string
      email: string
      phone: string
    }
    state: number
    options: {
      customChecks: {
        _id: string
        checkId: string
        mandatory: boolean
        checked: boolean
        label: string
        tooltip?: string
      }[]
    }
    certificates: UserCertificate[]
    signature: {
      draws: Draw[]
      electronicStamp?: ElectronicStamp
    }
  }
  type: 'SENDSIGN' | 'SEND_ELECTRONIC_SIGNATURE'
}

const getOnboardingSteps = async (oId: string, sId: string): Promise<OperationOnboardingSteps> => {
  return api
    .get(`/api/v2/operation/${oId}/signer/${sId}/onboarding-steps`)
    .then((response) => response.data)
}

const createVideoId = async (oId: string, sId: string): Promise<string> => {
  return api
    .get(`/api/v2/operation/${oId}/signer/${sId}/create-videoid`)
    .then((response) => response.data)
}

const getOperationSteps = async (oId: string, sId: string, token: string) => {
  return api
    .get(`/api/v2/operation/${oId}/signer/${sId}/signature-resume`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => response.data as Stepper)
}

const getOperation = async (oId: string, sId: string, token: string) => {
  return api
    .get(`/api/v2/operation/${oId}/signer/${sId}/signature-data`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => response.data as Operation)
}

const getFileContent = async (
  oId: string,
  sId: string,
  fileId: string,
  token: string
): Promise<string> => {
  return api
    .get(`/api/v2/operation/${oId}/signer/${sId}/received-document/${fileId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => response.data.content)
}

const operationService = {
  getOnboardingSteps,
  createVideoId,
  getOperationSteps,
  getOperation,
  getFileContent
}

export default operationService
